import { translate } from '../../../../shared/translate';
import BlobField from '../../../item_EmailTemplate/ComponentField/BlobField';
import { Box, InputAdornment } from '@material-ui/core';
import React from 'react';
import InputField from '../../../../components/FormPageMaker/Fields/InputField';
import InnerSelectField from '../../InnerSelectField';
import { AlignIconWithText } from './text';
import { Icon } from '../../../../components/IconRender';

export default [
  {
    label: translate.payment_details || '付款資訊',
    value: 'isPaymentData',
  },
  {
    label: translate.void,
    value: 'isVoidedMark',
  },
  {
    label: translate.reprint || '重印',
    value: 'isReprintMark',
  },
  {
    label: translate.takeaway || '外賣',
    value: 'isTakeawayMark',
  },
  require('./title').default,
  require('./text').default,
  {
    label: translate.logo,
    value: 'isLogo',
  },
  {
    label: translate.label_value || '標值',
    value: 'isLV',
    render: ({ suggestions, disabled, value, onChange }) => (
      <>
        <BlobField
          placeholder={'Label'}
          suggestions={suggestions}
          disabled={disabled}
          value={value?.label}
          onChange={(label) => {
            onChange({
              ...value,
              label,
            });
          }}
        />
        <BlobField
          placeholder={'Value'}
          suggestions={suggestions}
          disabled={disabled}
          value={value?.value}
          onChange={(v) => {
            onChange({
              ...value,
              value: v,
            });
          }}
        />
      </>
    ),
  },
  {
    label: translate.cut || '切割',
    value: 'isCut',
  },
  {
    label: translate.divider || '分隔',
    value: 'isDivider',
    render: ({ value, onChange, disabled }) => {
      return (
        <InputField
          style={{ height: '100%' }}
          variant={'standard'}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start" style={{ marginLeft: 8 }}>
                {translate.char || '符號'}
              </InputAdornment>
            ),
            style: { height: '100%' },
          }}
          placeholder={'-'}
          maxLength={1}
          value={value.text}
          onChange={(e) =>
            onChange({
              ...value,
              text: e.target.value,
            })
          }
          disabled={disabled}
        />
      );
    },
  },
  {
    label: translate.product,
    value: 'isItems',
    render: ({ value, disabled, onChange }) => {
      return (
        <Box
          px={1}
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <InnerSelectField
            disabled={disabled}
            placeholder={
              <>
                <Icon icon={'fa1'} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />
                <Icon icon={'faText'} style={{ verticalAlign: 'middle', fontSize: '0.8em' }} />
              </>
            }
            options={[
              {
                label: <AlignIconWithText iconOne="fa1" iconTwo="faText" />,
                value: 1,
                icon: <AlignIconWithText iconOne="fa1" iconTwo="faText" />,
              },
              {
                label: <AlignIconWithText iconOne="fa2" iconTwo="faText" />,
                value: 2,
                icon: <AlignIconWithText iconOne="fa2" iconTwo="faText" />,
              },
              {
                label: <AlignIconWithText iconOne="fa3" iconTwo="faText" />,
                value: 3,
                icon: <AlignIconWithText iconOne="fa3" iconTwo="faText" />,
              },
            ]}
            value={value?.size}
            onChange={(size) =>
              onChange({
                ...value,
                size,
              })
            }
          />
        </Box>
      );
    },
  },
  {
    label: translate.order_data,
    value: 'isOrderData',
  },
  {
    label: 'QR Code',
    value: 'isQRCode',
    render: ({ value, disabled, suggestions, onChange }) => {
      return (
        <BlobField
          placeholder={'xxxxxxxxxxxxxx'}
          suggestions={suggestions}
          disabled={disabled}
          value={value?.text}
          onChange={(text) => {
            onChange({
              ...value,
              text,
            });
          }}
        />
      );
    },
  },
  {
    label: translate.html,
    value: 'isHTML',
    render: ({ value, onChange, disabled }) => (
      <BlobField
        disabled={disabled}
        value={value?.text}
        onChange={(text) => {
          onChange({
            ...value,
            text,
          });
        }}
      />
    ),
  },
];
