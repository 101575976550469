const resolvers = {
  Query: {
    serviceLocationSlots: require('./Query.serviceLocationSlots').default,
    appointments: require('./Query.appointments').default,
    linkedAppointments: require('./Query.linkedAppointments').default,
  },
  ShopCollection: {
    allProducts: require('./ShopCollection_allProducts').default,
  },
  CompanyReceivePurchase: {
    items: require('./CompanyReceivePurchase_items').default,
  },
  CompanyStockAdjustment: {
    items: require('./CompanyStockAdjustment.items').default,
  },
  CompanyShop: {
    availableWarehouses: require('./CompanyShop.availableWarehouses').default,
    productUnions: require('./CompanyShop.productUnions').default,
  },
  Company: {
    reportOrders: require('./Company.reportOrders').default,
  },
  CompanyDiscount: {
    excludedDiscounts: require('./CompanyDiscount.excludedDiscounts').default,
    excludedCoupons: require('./CompanyDiscount.excludedCoupons').default,
  },
  CompanyCoupon: {
    excludedDiscounts: require('./CompanyCoupon.excludedDiscounts').default,
    excludedCoupons: require('./CompanyCoupon.excludedCoupons').default,
  },
  ShopVenue: {
    tables: require('./ShopVenue.tables').default,
  },
  CompanyStockTransfer: {
    itemsV2: require('./CompanyStockTransfer.itemsV2').default,
  },
};

export default resolvers;
